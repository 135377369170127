
import Storage from "@/util/Storage"
import {AccountList, UpdateAccount} from "@/Api/Money";
import {BodyType,UpdateAccountType} from "@/Api/Money/indexType";

// 账号列表
export function getAccountList(body:BodyType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(AccountList({ userId,token },body).then(res=>{
        return res.data
    }))
}

// 修改账户
export function setUpDateAccount(body:UpdateAccountType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(UpdateAccount({ userId,token },body).then(res=>{
        return res.data
    }))
}
