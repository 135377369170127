


export function filterDefaultList(list = []) {
    let defaultData = {}
    let List:any[] = []
    list.length && list.forEach(item=>{
        if ( item['defaultType'] == 0 ){
            defaultData = item
        }else{
            List.push(item)
        }
    })
    return {
        defaultData,
        List
    }
}


