import Storage from "@/util/Storage";
import {DelAccount} from "@/Api/Money";


export function removeAccount(id:string|number) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(DelAccount({ userId,token },{ id }).then(res=>{
        return res.data
    }))
}
